@import "../../../../assets/scss/variables.scss";

@import "../../../../../node_modules/bootstrap/scss/functions.scss";
@import "../../../../../node_modules/bootstrap/scss/variables.scss";
@import "../../../../../node_modules/bootstrap/scss/mixins.scss";
@import "../../../../../node_modules/bootstrap/scss/utilities.scss";

.carousel-home {
    margin-top: -10px;
}
