@import "../../../assets/scss/imports";

$bgProductYellow:    #fed25f;
$bgProductGreen:     #d2df37;
$bgProductBrown:     #df9926;

@include bg-variant(".bg-product-yellow", $bgProductYellow);
@include bg-variant(".bg-product-green", $bgProductGreen);
@include bg-variant(".bg-product-brown", $bgProductBrown);


@mixin bg-products($type, $position) {
    .bg-products-#{$type}-#{$position} {
        background: url('../../../assets/img/bg_texture_#{$type}.png');
        background-position: $position;
        background-repeat: no-repeat;
        background-size: 80%;
        padding: 5rem 0;
    }
} 

@include bg-products("light", left);
@include bg-products("light", right);
@include bg-products("dark", left);
@include bg-products("dark", right);

.description-product {
    text-align: justify;
    font-size: 1.25rem;
}

.img-product {
    margin: 2rem 0;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.4);
    width: 450px;
}

.img-product-logo {
    margin-left: -3rem;
    margin-right: 1rem;
    width: 100px;
}

.title-product {
    font-weight: bolder;
    font-size: 4rem;
}

.vertical-align {
    align-self: center;
}

.btn-product {
    padding: 0rem 0.5rem !important;
}

.paypal-button {
    width: 150px !important;
}