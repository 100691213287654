// Import custom variables (overrides Bootstrap default variables)
@font-face {
    font-family: 'Eurostile';
    font-style: normal;
    font-weight: normal;
    src: local('Eurostile'), url('../fonts/eurostile.woff') format('woff');
}

@import "./variables.scss";

// Import Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

$theme-colors: map-merge(
  (
    "brown":    $brown
  ),
  $theme-colors
);

// Import custom styles
@import "./global.scss";
@import "./navbar.scss";

$footer-height: 56px;

.content {
    padding-top: $navbar-height;
    background: url('../../assets/img/bg_page.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
}

.page {
    min-height: calc(100vh - #{$navbar-height} - #{$footer-height});
    padding-top: 2rem;
    padding-bottom: 2rem;
}

footer {
    background-color: darken($gray-blue, 5%);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $gray-blue;
    font-weight: 600;
    letter-spacing: 1px;
}

table {
    thead {
        th {
            color: $gray-blue;
        }
    }
}

textarea {
    resize: none;
}

.btn-brown {
    @include button-variant($brown, $brown);
}

@include bg-variant(".bg-brown", $brown);