// Base styling for the navbar
nav.navbar {
  box-shadow: $box-shadow;
  transition: background-color 0.2s ease;
  background-color: $brown;
  .navbar-brand {
    padding: 0;
  }
  .navbar-nav {
    .nav-item {
      @media (min-width: 992px) {
        border-right: 2px solid $orange;
      }

      &:last-child {
        border-right: none;
      }

      .nav-link {
        color: $gray-400;

        &:hover,
        &:active {
          color: $white;
        }
      }
    }
  }
}
