// Global styling for this template
body,
html {
  width: 100%;
  height: 100%;
}

// Typography
.text-white-75 {
  color: fade-out($white, .25);
}

// Custom horizontal rules
hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: $primary;
}

hr.light {
  border-color: $white;
}

// Button restyling
.btn {
  font-family: $font-family-sans-serif;
}

.btn-xl {
  padding: 0.75rem 3rem;
  font-size: 0.85rem;
  font-weight: 700;
  @extend .text-uppercase;
  @extend .border-0;
  @extend .rounded-pill;
}

// Page section padding
.page-section {
  padding: 8rem 0;
}
