// Variables

// Color overrides

// Set a custom color
$orange: #F26722 !default;
$brown: #653631 !default;
$gray-blue: #546670 !default;
$yellow: #FFCB07 !default;
$melon: #FEC87F !default;

// Set primary color to orange
$primary: $orange !default;

// Font overrides
// $font-family-sans-serif: "Eurostile", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$font-family-sans-serif: "Eurostile" !default;

// $font-family-serif: "Eurostile", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$font-family-serif: "Eurostile" !default;

$font-family-base: $font-family-serif !default;

$headings-font-family: $font-family-sans-serif !default;

// Custom variables
$navbar-height: 72px;