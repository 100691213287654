@import "../../../assets/scss/imports";

#about {
  background: url('../../../assets/img/bg_about.png');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  text-align: center;
  color: $white;

  .title-about {
    font-size: 6rem;
    font-weight: bold;
    padding: 0.2em 0.7em;
    border-radius: 60px;

    @media (max-width: 576px) {
      font-size: 3rem;
      display: block;
    }
  }

  .text-about { 
    font-size: 2.5rem;
    line-height: 1;
    text-shadow: 1px 4px 5px $gray-900;
    margin-top: 1rem;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      display: block;
    }
  }
}

#contact {
  background: url('../../../assets/img/bg_contacto.png');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  .title-contact {
    display: inline-block;
    font-size: 3.5rem;
    font-weight: bold;
    color: $melon;
    margin: 0;
  }

  .text-contact { 
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 1rem;
    color: $white;
    margin-top: 0;
  }
  

  @media (max-width: 768px) {
    text-align: center;

    .title-contact {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 2.5rem;
    }
    .text-contact { 
      text-align: justify;
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
  }
}