.loading {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    
    .spinner {
        position: absolute;
        top: 50%;
        width: 100vw;
        height: 70px;
        text-align: center;
    }
}